import axios from 'axios'

import token from "@/middlewares/token";
import authenticate from "@/middlewares/authenticate";
import validationManager from "@/middlewares/validationManager";

const api_url = process.env.VUE_APP_API_URL;

const axiosIns = axios.create({
  baseURL: api_url,
})

token(axiosIns);
authenticate(axiosIns);
validationManager(axiosIns);

export default axiosIns
